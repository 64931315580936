import { apiClient } from '@/custom_functions/api_client';

export default {
  namespaced: true,

  state: { lesson: null, lessonWithTestGroupSteps: null },

  getters: {
    getLesson: (state) => state.lesson,

    getLessonWithTestGroupSteps: (state) => state.lessonWithTestGroupSteps,

    getStep: (state) => (id) =>
      state.lesson && state.lesson.steps
        ? state.lesson.steps.find((step) => +step.id === +id)
        : null,
  },

  actions: {
    async fetchLesson({ commit }, payload) {
      const url = `/v2/courses/${payload.courseUrl}/units/${payload.unitId}/lessons/${payload.id}`;

      let response = await apiClient.get(url, {
        params: { group_test_steps: true },
      });
      commit('updateLesson', response.data.lesson);
    },

    async createStep(_, { lessonId, stepType, stepData }) {
      const response = await apiClient.post(`/v2/lessons/${lessonId}/steps`, {
        step: { type: stepType, text: '', data: stepData },
      });

      return response.data.step;
    },
  },

  mutations: {
    updateLesson(state, lesson) {
      const { steps } = lesson;

      const stepsWithoutGroupTestSteps = steps.flatMap((step) =>
        step.type === 'TestGroupStep' ? step.steps : [step],
      );

      const lessonWithoutTestGroupSteps = {
        ...lesson,
        steps: stepsWithoutGroupTestSteps,
      };

      state.lessonWithTestGroupSteps = lesson;
      state.lesson = lessonWithoutTestGroupSteps;
    },

    updateStep(state, newStep, _id) {
      state.lesson = {
        ...state.lesson,
        steps: state.lesson.steps.map((step) =>
          +step.id === +newStep.id ? newStep : step,
        ),
      };
    },
  },
};
