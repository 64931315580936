const types = [
  {
    displayed_type: 'video_course',
    course_type: ['stepik', 'video'],
    title: 'Видеокурс',
  },
  {
    displayed_type: 'online_course',
    course_type: ['stepik', 'video'],
    title: 'Поток',
  },
  { displayed_type: 'slurm_course', course_type: ['slurm'], title: 'Интенсив' },
  {
    displayed_type: 'slurm_record',
    course_type: ['stepik', 'video'],
    title: 'Запись интенсива',
  },
  {
    displayed_type: 'bundle_course',
    course_type: ['bundle', 'container'],
    title: 'Комплект',
  },
  {
    displayed_type: 'subscription_course',
    course_type: ['subscription'],
    title: 'Подписка',
  },
  {
    displayed_type: 'exam_course',
    course_type: ['certification'],
    title: 'Сертификация',
  },
  { displayed_type: 'demo_course', course_type: ['demo'], title: 'Демо-курс' },
  {
    displayed_type: 'night_school',
    course_type: ['slurm'],
    title: 'Вечерняя школа',
  },
  {
    displayed_type: 'gift_certificate_course',
    course_type: ['gift_certificate'],
    title: 'Подарочный сертификат',
  },
];

export function getDisplayedTypes() {
  return types.map((type) => type.displayed_type);
}

export function getTypeByDisplayedType(displayedType) {
  return types.find((type) => type.displayed_type === displayedType);
}

export function formatDisplayedType(value) {
  const type = types.find((type) => type.displayed_type === value);
  if (type) return type.title;
  return '';
}

export function getDisplayedTypeOptionsByCourseType(courseType) {
  return types
    .filter((type) => type.course_type.includes(courseType))
    .map((type) => ({ value: type.displayed_type, name: type.title }));
}

export function getDisplayedTypeOptions() {
  return types.map((type) => ({
    value: type.displayed_type,
    name: type.title,
  }));
}

export function getCourseTypeByDisplayedType(displayedType) {
  return types.find((type) => type.displayed_type === displayedType)
    .course_type[0];
}

export function isContainer(courseType) {
  return ['bundle', 'container', 'subscription'].includes(courseType);
}

export function isCombinationOfTypesCorrect(displayedType, courseType) {
  return getTypeByDisplayedType(displayedType).course_type.includes(courseType);
}

export function getDisplayedTypeOptionsExceptContainers() {
  return types
    .filter((type) => !isContainer(type.course_type[0]))
    .map((type) => ({ value: type.displayed_type, name: type.title }));
}

export function getDisplayedTypeOptionsOnlyContainers() {
  return types
    .filter((type) => isContainer(type.course_type[0]))
    .map((type) => ({ value: type.displayed_type, name: type.title }));
}

export function getDisplayedTypesExceptContainers() {
  return types
    .filter((type) => !isContainer(type.course_type[0]))
    .map((type) => type.displayed_type);
}

export function getDisplayedTypesOnlyContainers() {
  return types
    .filter((type) => isContainer(type.course_type[0]))
    .map((type) => type.displayed_type);
}

export function getCourseTypesWithoutContainers() {
  return Array.from(
    new Set(
      types
        .filter((type) => !isContainer(type.course_type[0]))
        .map((type) => type.course_type[type.course_type.length - 1]),
    ),
  );
}
