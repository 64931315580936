<template>
  <div>
    <b-button
      variant="link"
      class="text-dark no-outline p-0 mb-3"
      aria-controls="collapse-courses"
      @click="toggleFormVisible"
    >
      <span class="h3 me-2">На какие курсы действует</span>
      <font-awesome-icon
        class="arrow"
        :class="{ collapsed: !isFormVisible }"
        icon="angle-down"
        size="lg"
      />
    </b-button>

    <b-collapse
      id="collapse-courses"
      v-model="isFormVisible"
      class="mt-2"
    >
      <div class="form-check mb-3">
        <input
          id="for-all-courses"
          v-model="currentIsForAllCourses"
          type="checkbox"
          name="for-all-courses"
          class="form-check-input"
        />
        <label
          for="for-all-courses"
          class="form-check-label"
        >
          Все текущие и будущие курсы
        </label>
      </div>
      <transition
        name="fade"
        mode="out-in"
      >
        <div v-if="!currentIsForAllCourses">
          <div v-if="props.courses">
            <button
              class="btn btn-outline-primary mb-3"
              @click="toggleAllCourses"
            >
              {{ toggleAllCoursesButtonText }}
            </button>
            <div class="row">
              <div
                v-for="course in courses"
                :key="course.id"
                class="col-4"
              >
                <div class="mb-3">
                  <div class="form-check">
                    <input
                      :id="`course-in-campaign-${course.id}`"
                      v-model="currentSelectedCourses"
                      class="form-check-input"
                      type="checkbox"
                      :value="course.id"
                    />
                    <label
                      :for="`course-in-campaign-${course.id}`"
                      class="form-check-label"
                    >
                      {{ course.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <b-spinner />
          </div>
        </div>
      </transition>
    </b-collapse>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  selectedCourses: {
    type: Array,
    required: true,
  },
  isForAllCourses: {
    type: Boolean,
    required: true,
  },
  courses: {
    type: [Array, null],
    required: true,
  },
});

const emit = defineEmits(['update:selectedCourses', 'update:isForAllCourses']);

const isFormVisible = ref(true);
const toggleFormVisible = () => {
  isFormVisible.value = !isFormVisible.value;
};

const currentSelectedCourses = ref([...props.selectedCourses]);
const currentIsForAllCourses = ref(props.isForAllCourses);

const isAllCoursesWasSelected = computed(() => {
  return currentSelectedCourses.value.length === props.courses.length;
});

const toggleAllCourses = () => {
  currentSelectedCourses.value = !isAllCoursesWasSelected.value
    ? props.courses.map((course) => course.id)
    : [];
};

const toggleAllCoursesButtonText = computed(() => {
  return isAllCoursesWasSelected.value
    ? 'Отменить выбор всех курсов'
    : 'Выбрать все курсы';
});

watch(
  () => props.selectedCourses,
  (value) => {
    currentSelectedCourses.value = value;
  },
);

watch(currentSelectedCourses, (value) => {
  emit('update:selectedCourses', value);
});

watch(currentIsForAllCourses, (value) => {
  if (!props.courses) {
    store.dispatch('courses/fetchCoursesOmitPagination');
  }

  emit('update:isForAllCourses', value);
});
</script>

<style scoped>
.no-outline:focus {
  box-shadow: none;
}

.arrow {
  transition: all 0.3s;
}

.collapsed {
  transform: rotate(180deg);
}
</style>
