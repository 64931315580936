<template>
  <div class="form-check form-switch mb-0">
    <input
      :id="id"
      v-model="isToggled"
      class="form-check-input"
      type="checkbox"
    />
    <label
      class="form-check-label"
      :for="id"
    >
      {{ props.text }}
    </label>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const isToggled = ref(props.modelValue);

watch(isToggled, (newValue) => emit('update:modelValue', newValue));
</script>
