import { createRouter, createWebHistory } from 'vue-router';

import Login from '@/views/auth/Login';

import CoursesContainersEdit from '@/views/courses/containers/ContainerEdit';
import CoursesContainersNew from '@/views/courses/containers/ContainerNew';
import CoursesEdit from '@/views/courses/CourseEdit';
import CoursesNew from '@/views/courses/CourseNew';
import CoursesIndex from '@/views/courses/Index';
import CoursesProductEdit from '@/views/courses/products/ProductEdit';
import CoursesProductNew from '@/views/courses/products/ProductNew';
import CoursesProductShow from '@/views/courses/products/ProductShow';

import StandsSessionsIndex from '@/views/stands_sessions/Index';
import StandsSessionsShow from '@/views/stands_sessions/Show';

import ZonesEdit from '@/views/zones/Edit';
import ZonesIndex from '@/views/zones/Index';
import ZonesNew from '@/views/zones/New';

import UnitsEdit from '@/views/units/Edit';
import UnitsNew from '@/views/units/New';

import LessonsEdit from '@/views/lessons/Edit';

import TagsEdit from '@/views/tags/Edit';
import TagsPolicy from '@/views/tags/Policy';

import PromoCodesIndex from '@/views/promo_codes/Index';
import PromoCodesNew from '@/views/promo_codes/New';
import PromoCodesEdit from '@/views/promo_codes/PromoCodesEdit';

import ScriptRunnersIndex from '@/views/script_runners/Index';
import ScriptRunnersShow from '@/views/script_runners/Show';

import CompaniesEdit from '@/views/companies/Edit';
import CompaniesIndex from '@/views/companies/Index';
import CompaniesNew from '@/views/companies/New';
import CompaniesShow from '@/views/companies/Show';

import SpeakersIndex from '@/views/speakers/Index';

import WebinarsEdit from '@/views/webinars/Edit';
import WebinarsIndex from '@/views/webinars/Index';
import WebinarsNew from '@/views/webinars/New';

import NotificationsNew from '@/views/notifications/New';

import Errbit from '@/views/Errbit';

import Search from '@/views/Search.vue';

import OrdersIndex from '@/views/orders/Index';
import OrdersNew from '@/views/orders/New';
import OrdersShow from '@/views/orders/Show';

import SupportIndex from '@/views/support/Index';

import ReportsEdit from '@/views/reports/Edit';
import ReportsIndex from '@/views/reports/Index';
import ReportsNew from '@/views/reports/New';

import reviewers from '@/router/modules/reviewers';

import directions from '@/router/modules/directions';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },

  // errbit panel
  {
    path: '/errbit',
    name: 'errbit',
    component: Errbit,
    meta: {
      requiresAuth: true,
    },
  },

  // admin panel
  { path: '/', redirect: '/courses' },

  {
    path: '/search/page_:page',
    name: 'search_page',
    component: Search,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      requiresAuth: true,
    },
    props: true,
    redirect: { name: 'search_page', params: { page: 1 } },
  },

  {
    path: '/courses',
    name: 'courses_index',
    component: CoursesIndex,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/courses/products/new',
    name: 'products_new',
    component: CoursesProductNew,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },

  {
    path: '/courses/products/:id',
    name: 'products_show',
    component: CoursesProductShow,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/courses/products/:id/edit',
    name: 'products_edit',
    component: CoursesProductEdit,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/courses/new',
    name: 'courses_new',
    component: CoursesNew,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },

  {
    path: '/courses/:id/edit',
    name: 'courses_edit',
    component: CoursesEdit,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/courses/containers/new',
    name: 'containers_new',
    component: CoursesContainersNew,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },

  {
    path: '/courses/containers/:id/edit',
    name: 'containers_edit',
    component: CoursesContainersEdit,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
    props: true,
  },

  {
    path: '/courses/reviews/page_:page',
    name: 'courses_reviews',
    component: () => import('@/views/courses/Reviews'),
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
    props: true,
  },

  {
    path: '/stands_sessions',
    name: 'stands_sessions_index',
    component: StandsSessionsIndex,
    meta: {
      requiresAuth: true,
    },
    redirect: { name: 'stands_sessions_index_page', params: { page: 1 } },
  },

  {
    path: '/stands_sessions/page_:page',
    name: 'stands_sessions_index_page',
    component: StandsSessionsIndex,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/stands_sessions/:id',
    name: 'stands_sessions_show',
    component: StandsSessionsShow,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/zones',
    name: 'zones_index',
    component: ZonesIndex,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/zones/new',
    name: 'zones_new',
    component: ZonesNew,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/zones/:value/edit',
    name: 'zones_edit',
    component: ZonesEdit,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/units/new',
    name: 'units_new',
    component: UnitsNew,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },

  {
    path: '/units/:id/edit',
    name: 'units_edit',
    component: UnitsEdit,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/lessons/:id/step/:stepId',
    name: 'lessons_edit',
    component: LessonsEdit,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/tags',
    name: 'tags_edit',
    component: TagsEdit,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/tags_policy',
    name: 'tags_policy',
    component: TagsPolicy,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/promo_codes/page_:page',
    name: 'promo_codes_index',
    component: PromoCodesIndex,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/promo_codes/new',
    name: 'promo_codes_new',
    component: PromoCodesNew,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/promo_codes/:id/edit',
    name: 'promo_codes_edit',
    component: PromoCodesEdit,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/users',
    name: 'users_index',
    component: () => import('@/views/users/Index'),
    meta: {
      requiresAuth: true,
    },

    redirect: { name: 'users_index_page', params: { page: 1 } },
  },

  {
    path: '/users/page_:page',
    name: 'users_index_page',
    component: () => import('@/views/users/Index'),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/users/:id/edit',
    name: 'users_edit',
    component: () => import('@/views/users/Edit'),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/users/new',
    name: 'users_new',
    component: () => import('@/views/users/New'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/script_runners',
    name: 'script_runners_index',
    component: ScriptRunnersIndex,
    meta: {
      requiresAuth: true,
    },
    redirect: { name: 'script_runners_index_page', params: { page: 1 } },
  },

  {
    path: '/script_runners/page_:page',
    name: 'script_runners_index_page',
    component: ScriptRunnersIndex,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/script_runners/:id',
    name: 'script_runners_show',
    component: ScriptRunnersShow,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/companies',
    name: 'companies_index',
    component: CompaniesIndex,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/companies/new',
    name: 'companies_new',
    component: CompaniesNew,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/companies/:id',
    name: 'companies_show',
    component: CompaniesShow,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/companies/:id/edit',
    name: 'companies_edit',
    component: CompaniesEdit,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/speakers',
    name: 'speakers_index',
    component: SpeakersIndex,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/webinars/page_:page',
    name: 'webinars_index_page',
    component: WebinarsIndex,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/webinars',
    name: 'webinar_index',
    component: CoursesIndex,
    meta: {
      requiresAuth: true,
    },
    redirect: { name: 'webinars_index_page', params: { page: 1 } },
  },

  {
    path: '/webinars/new',
    name: 'webinars_new',
    component: WebinarsNew,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/webinars/:id/edit',
    name: 'webinars_edit',
    component: WebinarsEdit,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/notifications/new',
    name: 'notifications_new',
    component: NotificationsNew,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/orders',
    name: 'orders_index',
    component: OrdersIndex,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/orders/new',
    name: 'orders_new',
    component: OrdersNew,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/orders/:id',
    name: 'orders_show',
    component: OrdersShow,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/support',
    name: 'support_index',
    component: SupportIndex,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/support/:id',
    name: 'support_show',
    component: SupportIndex,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/reports',
    name: 'reports_index',
    component: ReportsIndex,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/reports/new',
    name: 'reports_new',
    component: ReportsNew,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/reports/:id',
    name: 'reports_edit',
    component: ReportsEdit,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  ...reviewers,
  ...directions,
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      localStorage.userRole === 'admin' ||
      localStorage.userRole === 'slurm_manager'
    ) {
      next();
    } else {
      next({
        path: '/login',
        query: { nextUrl: to.fullPath },
      });
      localStorage.removeItem('userRole');
    }
  } else {
    next();
  }
});

export default router;
