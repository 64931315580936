<template>
  <span class="d-flex gap-2">
    <div
      v-for="step in stepsWithIndexes"
      :key="step.id"
    >
      <button
        v-if="step.type !== 'TestGroupStep'"
        class="btn btn-sm step-nav-btn fs-6"
        :class="buttonStepClass(step)"
        @click="toOtherStep(step.id)"
      >
        {{ step.displayIndex }}
      </button>
      <div
        v-else
        class="btn-group"
        role="group"
      >
        <button
          v-for="testStep in step.steps"
          :key="testStep.id"
          :class="buttonInTestGroupStepClass(testStep.id)"
          class="btn btn-sm step-nav-btn fs-6"
          @click="toOtherStep(testStep.id)"
        >
          {{ testStep.displayIndex }}
        </button>
      </div>
    </div>

    <div class="vertical-divider mx-2" />

    <div
      v-if="!stepChanged"
      class="d-flex gap-2"
    >
      <button
        class="btn btn-outline-primary btn-sm step-nav-btn"
        @click="showModalCreateStep = true"
      >
        <font-awesome-icon icon="plus" />
      </button>

      <b-modal
        v-model="showModalCreateStep"
        hide-footer
        title="Выберите тип нового шага"
      >
        <div class="d-grid">
          <section
            v-for="(group, index) in STEP_TYPE_GROUPS"
            :key="`${group.title}-${index}`"
          >
            <h3 class="fs-6 fw-normal text-center">{{ group.title }}</h3>
            <div class="d-flex flex-column gap-2">
              <button
                v-for="type in group.types"
                :key="`${type.type}-${index}`"
                :class="`btn-outline-${group.colorClass}`"
                class="btn"
                @click="createStep(type.type)"
              >
                {{ type.title }}
              </button>
            </div>
            <hr class="mt-3 mb-2" />
          </section>
          <b-button
            class="mt-2"
            block
            variant="outline-danger"
            @click="showModalCreateStep = false"
          >
            Отмена
          </b-button>
        </div>
      </b-modal>

      <button
        v-if="lesson.steps.length > 1"
        class="btn btn-outline-primary"
        @click="showModalChangeOrder = true"
      >
        Изменить порядок
      </button>

      <b-modal
        v-model="showModalChangeOrder"
        hide-footer
        title="Порядок шагов"
      >
        <draggable
          v-model:model-value="stepsOrder"
          item-key="id"
        >
          <template #item="{ element }">
            <div class="card mb-2 d-flex flex-row align-items-center p-4">
              <font-awesome-icon
                icon="grip-vertical"
                class="text-muted drag-icon me-3"
              />
              <span>
                {{ element.index + 1 }} шаг: {{ formatStepType(element.type) }}
              </span>
            </div>
          </template>
        </draggable>
        <button
          class="btn btn-primary mt-3 btn-block"
          @click="changeStepsOrder"
        >
          Сохранить
        </button>
      </b-modal>
    </div>
  </span>
</template>

<script>
import { formatStepType, makeToast } from '@/custom_functions/custom_functions';

export default {
  name: 'StepsList',

  props: { stepChanged: { type: Boolean, default: true } },

  data() {
    return {
      showModalCreateStep: false,
      showModalChangeOrder: false,
      stepsOrder: null,

      STEP_TYPE_GROUPS: {
        theory: {
          types: [
            { type: 'TextStep', title: 'Текст' },
            { type: 'VideoStep', title: 'Видео' },
          ],
          colorClass: 'secondary',
          title: 'Теория',
        },
        tests: {
          types: [
            { type: 'QuizStep', title: 'Выбор' },
            { type: 'SortStep', title: 'Сортировка' },
            { type: 'MatchStep', title: 'Сопоставление' },
          ],
          colorClass: 'success',
          title: 'Тесты',
        },
        practice: {
          types: [
            { type: 'TextTaskStep', title: 'Текстовая задача' },
            { type: 'InteractStep', title: 'Интерактив' },
            { type: 'DeferredStep', title: 'Ручная проверка' },
          ],
          colorClass: 'primary',
          title: 'Практика',
        },
      },
    };
  },

  computed: {
    lesson() {
      return this.$store.getters['lesson/getLesson'];
    },

    lessonWithTestGroupSteps() {
      return this.$store.getters['lesson/getLessonWithTestGroupSteps'];
    },

    stepsWithIndexes() {
      let counter = 1;
      return this.lessonWithTestGroupSteps.steps.map((step) => {
        if (step.type !== 'TestGroupStep') {
          return {
            ...step,
            displayIndex: counter++,
          };
        }
        return {
          ...step,
          steps: step.steps.map((testStep) => ({
            ...testStep,
            displayIndex: counter++,
          })),
        };
      });
    },
  },

  watch: {
    showModalChangeOrder(value) {
      if (value)
        this.stepsOrder = this.lesson.steps.map((step, index) => ({
          ...step,
          index,
        }));
    },
  },

  methods: {
    formatStepType,

    buttonStepClass(step) {
      const PRACTICAL_STEP_TYPES = this.STEP_TYPE_GROUPS.practice.types.map(
        (type) => type.type,
      );

      if (step.id == +this.$route.params.stepId)
        return this.stepChanged ? 'btn-danger' : 'btn-primary';

      if (PRACTICAL_STEP_TYPES.includes(step.type)) {
        return 'btn-outline-primary';
      } else {
        return 'btn-outline-secondary';
      }
    },

    buttonInTestGroupStepClass(id) {
      if (id == +this.$route.params.stepId)
        return this.stepChanged ? 'btn-danger' : 'btn-success';
      return 'btn-outline-success';
    },

    toOtherStep(id) {
      if (id !== +this.$route.params.stepId) {
        if (this.stepChanged)
          makeToast(
            'Пожалуйста, сохраните текущий шаг для перехода к следующему.',
            'danger',
          );
        else
          this.$router.push(
            `/lessons/${this.$route.params.id}/step/${id}?course=${this.$route.query.course}&unit=${this.$route.query.unit}`,
          );
      }
    },

    getDataForNewStep(stepType) {
      switch (stepType) {
        case 'TextStep':
        case 'VideoStep':
        case 'DeferredStep': {
          return null;
        }

        case 'TextTaskStep': {
          return {
            regexp: 'answer',
            settings: { ignore_case: true, use_regexp: false },
          };
        }

        case 'QuizStep': {
          return { variants: [], multipleAnswers: false };
        }

        case 'SortStep': {
          return { variants: [] };
        }

        case 'MatchStep': {
          return { variants: { questions: [], answers: [] } };
        }

        case 'InteractStep': {
          return { script_check: '', settings: { show_textarea: false } };
        }
      }
    },

    goToCreatedStep(stepId) {
      const { id } = this.$route.params;
      const { course, unit } = this.$route.query;
      this.$router.push(
        `/lessons/${id}/step/${stepId}?course=${course}&unit=${unit}`,
      );
    },

    async createStep(stepType) {
      const stepData = this.getDataForNewStep(stepType);

      const { id: createdStepId } = await this.$store.dispatch(
        'lesson/createStep',
        {
          lessonId: this.lesson.id,
          stepType,
          stepData,
        },
      );
      await this.$store.dispatch('lesson/fetchLesson', {
        id: this.lesson.id,
        courseUrl: this.$route.query.course,
        unitId: this.$route.query.unit,
      });
      this.goToCreatedStep(createdStepId);

      this.showModalCreateStep = false;
    },

    async changeStepsOrder() {
      const steps = this.stepsOrder.map((step, index) => ({
        id: step.id,
        weight: index,
      }));
      await this.$apiClient.patch(
        `/v2/lessons/${this.lesson.id}/steps/reorder`,
        { steps },
      );
      await this.$store.dispatch('lesson/fetchLesson', {
        id: this.lesson.id,
        courseUrl: this.$route.query.course,
        unitId: this.$route.query.unit,
      });
      this.showModalChangeOrder = false;
      makeToast('Новый порядок шагов успешно сохранен.', 'success');
    },
  },
};
</script>

<style>
.step-nav-btn {
  width: 40px;
  height: 40px;
}

.drag-icon {
  cursor: grab;
}

.vertical-divider {
  width: 1px;
  height: 40px;
  background-color: #dee2e6;
}
</style>
