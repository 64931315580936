<template>
  <div class="container">
    <div
      class="d-flex flex-row justify-content-between align-items-center mb-4"
    >
      <h2>Создать Tinkoff рассрочку</h2>
      <div>
        <router-link
          to="/orders"
          class="btn btn-outline-primary"
        >
          Вернуться к заказам
        </router-link>
      </div>
    </div>

    <div v-if="coursesOptions && usersOptions">
      <div class="row">
        <div class="col">
          <CustomSelect
            id="student-email"
            v-model:model-value="order.userEmail"
            :options="usersOptions"
            :search="fetchUsers"
            :errors="errors.userEmail"
            label="Поиск студента по email"
            placeholder="Выберите студента"
            class="mb-3"
            required
          />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <CustomSelect
            id="course-ids"
            v-model:model-value="order.courseIds"
            :options="coursesOptions"
            :errors="errors.courseIds"
            label="Курсы"
            placeholder="Выберите курсы"
            class="mb-3"
            required
            multiple
          />
        </div>

        <div class="col">
          <CustomSelect
            id="promo-codes"
            v-model:model-value="order.promoCode"
            :options="promoCodes"
            :errors="errors.promoCode"
            label="Промокод"
            placeholder="Выберите промокод"
            class="mb-3"
            required
          />
        </div>
      </div>

      <div class="d-flex flex-row align-items-end">
        <NumberInput
          v-model:model-value="order.amount"
          :disabled="!order.isCustomAmount"
          label="Стоимость"
          class="col-2 me-2"
        >
          <template #prepend="{ _class }">
            <span :class="_class">
              <font-awesome-icon icon="ruble-sign" />
            </span>
          </template>
        </NumberInput>
        <div class="mb-3">
          <button
            v-if="order.isCustomAmount"
            class="btn btn-light"
            @click="resetAmount"
          >
            Вернуть базовую стоимость
          </button>
          <button
            v-else
            class="btn btn-light"
            @click="changeAmount"
          >
            Изменить стоимость
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label class="mb-2">
            <input
              v-model="order.isReloadLink"
              type="checkbox"
              class="form-check-input mb-3"
            />
            Обновить существующую ссылку
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button
            class="btn btn-primary"
            :class="tinkoffLink && 'me-2'"
            @click="createOrder"
          >
            Сгенерировать ссылку на Tinkoff рассрочку
          </button>
          <button
            v-if="tinkoffLink"
            class="btn btn-light"
            @click="
              copyText(
                tinkoffLink,
                'Ссылка скопирована',
                'Не удалось скопировать ссылку',
              )
            "
          >
            Копировать ссылку
          </button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="loader text-center"
    >
      <b-spinner />
    </div>
  </div>
</template>

<script setup>
import CustomSelect from '@/components/CustomSelect.vue';
import NumberInput from '@/components/NumberInput.vue';
import { useCustomSelectOptions } from '@/composables/use_custom_select_options';
import { computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { copyText, makeToast } from '../../custom_functions/custom_functions';

const store = useStore();

store.dispatch('courses/fetchCoursesOmitPagination', {
  fields: ['current_price'],
});
store.dispatch('users/fetchUsers');
store.dispatch('orders/cleanTinkoffLink');

const users = computed(() => store.getters['users/getUsers']);
const courses = computed(
  () => store.getters['courses/getCoursesOmitPagination'],
);
const errors = computed(() => store.getters['orders/getErrors']);

const coursesOptions = computed(() =>
  useCustomSelectOptions(courses.value, 'id', 'name'),
);
const usersOptions = computed(() =>
  useCustomSelectOptions(users.value, 'email', 'email'),
);

const promoCodes = ref([
  {
    name: '4 мес., 5.5%',
    value: 'installment_0_0_4_5,5_5',
  },
  {
    name: '6 мес., 7,7%',
    value: 'installment_0_0_6_7,7_5',
  },
  {
    name: '12 мес., 13,5%',
    value: 'installment_0_0_12_13,5_5',
  },
]);

//TODO: move to store
const order = reactive({
  courseIds: [],
  userEmail: null,
  promoCode: null,
  amount: 0,
  isReloadLink: false,
  isCustomAmount: false,
});

watch(order, () => {
  if (Object.keys(errors.value).length) {
    store.dispatch('orders/validateOrder', order);
  }
});

watch(
  () => order.courseIds,
  () => {
    order.isCustomAmount = false;
    calculateAmound();
  },
);

function calculateAmound() {
  if (order.courseIds.length) {
    order.amount = order.courseIds.reduce(
      (totalAmount, id) =>
        (totalAmount += +courses.value.find((course) => course.id === id)
          .current_price),
      0,
    );
  } else order.amount = 0;
}

function changeAmount() {
  order.isCustomAmount = true;
}

function resetAmount() {
  order.isCustomAmount = false;
  calculateAmound();
}

async function createOrder() {
  await store.dispatch('orders/createOrder', order);
  if (tinkoffLink.value) {
    //TODO: remove await
    await makeToast('Запись сохранена!', 'success');
    copyText(
      tinkoffLink.value,
      'Ссылка скопирована',
      'Не удалось скопировать ссылку',
    );
  }
}

async function fetchUsers(email) {
  if (email.length) await store.dispatch('users/fetchUsersByEmail', email);
}

const tinkoffLink = computed(() => store.getters['orders/getTinkoffLink']);
</script>
