<template>
  <div class="row align-items-center">
    <div class="col-3">
      <input
        v-model="limit"
        type="number"
        class="form-control"
        :required="!withoutLimit"
        :disabled="withoutLimit"
        placeholder="Количество попыток"
      />
    </div>

    <div class="form-check col">
      <input
        id="without-limit"
        v-model="withoutLimit"
        class="form-check-input"
        type="checkbox"
      />
      <label
        class="form-check-label"
        for="without-limit"
      >
        Не ограничивать попытки
      </label>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: [Number, null],
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const withoutLimit = ref(!props.modelValue);
const limit = ref(props.modelValue);

watch(withoutLimit, (value) => {
  if (value) {
    limit.value = null;
  }
});

watch(limit, (value) => {
  emit('update:modelValue', value);
});

watch(
  () => props.modelValue,
  (value) => {
    limit.value = value;
    withoutLimit.value = !value;
  },
);
</script>
