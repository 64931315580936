<template>
  <div class="mb-4">
    <div class="row">
      <div class="col-8">
        <div class="d-flex no-block align-items-center justify-content-between">
          <h3 class="mb-3">Статистика</h3>

          <button
            type="submit"
            class="btn btn-outline-primary btn-sm"
            :class="{ disabled: promoCodeAction !== 'show' }"
            :disabled="promoCodeAction !== 'show'"
            @click.prevent="addNewPromoCode"
          >
            Добавить Промокод
          </button>
        </div>

        <table class="table">
          <thead>
            <tr>
              <th
                scope="col"
                class="promo-code-col"
              >
                Промокод
              </th>
              <th scope="col">Использовано</th>
              <th scope="col" />
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(promoCode, index) in campaign.promo_codes"
              :key="index"
            >
              <td>
                <template
                  v-if="
                    promoCodeAction !== 'show' &&
                    index === currentPromoCodeIndex
                  "
                >
                  <input
                    v-model="promoCode.name"
                    type="text"
                    class="form-control form-control-sm"
                    autofocus
                  />
                </template>

                <template v-else>
                  {{ promoCode.name }}
                </template>
              </td>

              <td>{{ promoCode.usage_count }}</td>

              <td class="text-end">
                <template
                  v-if="
                    promoCodeAction !== 'show' &&
                    index === currentPromoCodeIndex
                  "
                >
                  <button
                    class="btn btn-sm btn-outline-primary me-2"
                    @click="undoPromoCodeChanges(index)"
                  >
                    Отменить
                  </button>

                  <button
                    class="btn btn-sm btn-outline-primary"
                    @click="
                      promoCodeAction === 'new'
                        ? savePromoCode(promoCode, index)
                        : updatePromoCode(promoCode)
                    "
                  >
                    Применить
                  </button>
                </template>

                <template v-else>
                  <button
                    class="btn btn-sm btn-link me-2"
                    :class="{ disabled: promoCodeAction !== 'show' }"
                    :disabled="promoCodeAction !== 'show'"
                    @click="editPromoCode(promoCode, index)"
                  >
                    <font-awesome-icon icon="pen" />
                  </button>

                  <button
                    class="btn btn-sm btn-link"
                    :class="{ disabled: promoCodeAction !== 'show' }"
                    :disabled="promoCodeAction !== 'show'"
                    @click="
                      currentPromoCodeIndex = index;
                      $bvModal.show('delete-promo-code-modal');
                    "
                  >
                    <font-awesome-icon
                      icon="trash"
                      class="text-danger"
                    />
                  </button>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-modal
      id="delete-promo-code-modal"
      hide-footer
    >
      <template v-slot:modal-title>Подтвердите действие</template>
      <div class="d-block text-center">
        <h4 v-if="campaign.promo_codes[currentPromoCodeIndex]">
          Промокод «{{ campaign.promo_codes[currentPromoCodeIndex].name }}»
          будет удалён
        </h4>
      </div>
      <b-button
        class="mt-3 me-2"
        block
        variant="danger"
        @click="deletePromoCode(campaign.promo_codes[currentPromoCodeIndex])"
      >
        Удалить
      </b-button>
      <b-button
        class="mt-3"
        block
        @click="
          $bvModal.hide('delete-promo-code-modal');
          currentPromoCodeIndex = null;
        "
      >
        Отменить
      </b-button>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'CampaignPromoStatistics',

  props: {
    campaign: Object,
  },

  data() {
    return {
      promoCodeAction: 'show',
      currentPromoCodeIndex: null,
      tempPromoCodeName: '',
    };
  },

  methods: {
    addNewPromoCode() {
      this.campaign.promo_codes.push({ name: '', usage_count: 0 });
      this.promoCodeAction = 'new';
      this.currentPromoCodeIndex = this.campaign.promo_codes.length - 1;
    },

    undoPromoCodeChanges(index) {
      if (this.promoCodeAction === 'new') {
        this.campaign.promo_codes.splice(index, 1);
      } else if (this.promoCodeAction === 'edit') {
        this.campaign.promo_codes[index].name = this.tempPromoCodeName;
        this.tempPromoCodeName = '';
      }
      this.promoCodeAction = 'show';
    },

    async savePromoCode(promoCode, index) {
      if (promoCode.name.length === 0) {
        return this.$root.$bvToast.toast(
          'Пожалуйста, заполните имя для промокода',
          {
            title: 'Уведомление',
            variant: 'danger',
            autoHideDelay: 3000,
          },
        );
      }

      let postData = {
        promo_code: {
          name: promoCode.name,
          campaign_id: this.campaign.id,
        },
      };

      let response = await this.$apiClient.post('/v2/promo_codes', postData);

      this.campaign.promo_codes[index] = response.data.promo_code;

      this.promoCodeAction = 'show';
    },

    async deletePromoCode(promoCode) {
      await this.$apiClient.delete(`/v2/promo_codes/${promoCode.id}`);
      this.campaign.promo_codes.splice(
        this.campaign.promo_codes.indexOf(promoCode),
        1,
      );
      this.$bvModal.hide('delete-promo-code-modal');
      this.currentPromoCodeIndex = null;
    },

    editPromoCode(promoCode, index) {
      this.promoCodeAction = 'edit';
      this.currentPromoCodeIndex = index;
      this.tempPromoCodeName = promoCode.name;
    },

    async updatePromoCode(promoCode) {
      if (promoCode.name.length === 0) {
        return this.$root.$bvToast.toast(
          'Пожалуйста, заполните имя для промокода',
          {
            title: 'Уведомление',
            variant: 'danger',
            autoHideDelay: 3000,
          },
        );
      }

      let data = {
        promo_code: promoCode,
      };

      await this.$apiClient.patch(`/v2/promo_codes/${promoCode.id}`, data);

      this.promoCodeAction = 'show';
    },
  },
};
</script>

<style scoped>
.promo-code-col {
  width: 300px;
}
</style>
